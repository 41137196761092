//@flow

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import SEO from '../../../components/seo';
import { PrivateRoute } from '../../../services/auth';

import ChapterScreen from '../../../screens/Chapter';


const ChapterPage = () => {
  const { episode1, episode2, episode3 } = useStaticQuery(
    graphql`
      query Chapter5GetImages($maxWidth: Int) {
        episode1: file(relativePath: { eq: "video/thumb15.png" }) {
          ...defaultImage
        }
        episode2: file(relativePath: { eq: "video/thumb16.png" }) {
          ...defaultImage
        },
        episode3: file(relativePath: { eq: "video/thumb17.png" }) {
          ...defaultImage
        }
      }
    `,
    {
      maxWidth: 420,
    }
  );
  return (
    <>
      <SEO title="Chapter" />
      <ChapterScreen
        chapterNumber={5}
        images={[episode1, episode2, episode3]}
      />
    </>
  );
};

export default PrivateRoute(ChapterPage);